import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { Location } from "@reach/router"

const Header = ({ avatar }) => (
  <header className="Header">
    <Location>
      {({ location }) => {
        return (
          <>
            <Link to="/">
              <img
                src={avatar}
                alt="Profile picture"
                className="Header__avatar"
              />
            </Link>
            <div className="Header__link-items">
              <Link to="/about">About me</Link>
              {/* <Link to="/projects">Projects</Link> */}
              <Link to="/blog">Blog</Link>
            </div>
          </>
        )
      }}
    </Location>
  </header>
)

Header.propTypes = {
  avatar: PropTypes.string,
}

Header.defaultProps = {
  avatar: ``,
}

export default Header
